import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BackToTop from "../../components/back-to-top"
import { Container, Row, Col } from "reactstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"

const LeahHixon = () => {
  return (
    <Layout>
      <Seo title="Doctor Leah Hixon, DVM" />
      <div className="hero-adjustment pb-0">
        <div className="container">
          <div className="hero-content-wrapper">
            <div className="row no-gutters">
              <div className="col-12 col-md-7">
                <div className="hero-dog-bg">
                  <div className="dog-bg"></div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="hero-video">
                  <div>
                    <div>
                      <div className="video-modal-btn no-video">
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/img/tplo-video.jpg"
                          alt="video"
                          className="video-img img-fluid v-h"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-only-heading inner-page-title text-right arthroscopy-hero no-videoHero">
              <h1>
                Meet <br />
                Dr. Leah
                <br />
                Hixon
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Container className="acl-container mb-5 mt-5 mt-md-0">
        <div className="doctor-body">
          <Row className="border-none">
            <Col md={4} className="img-resp">
              <LazyLoadImage
                effect="blur"
                src="/assets/img/dr-leah-hixon.jpg"
                alt="Doctor"
                className="img-fluid mb-4"
              />
            </Col>
            <Col md={8}>
              <p>
                Originally from Woodstock, Georgia, Dr. Leah Hixon went to University
                of South Alabama on a track scholarship prior to attending University
                of Georgia for veterinary school. She then completed a rotating
                internship at Mississippi State University followed by a surgery
                specialty internship at Gulf Coast Veterinary Specialists in Houston,
                TX. Following her internships, she returned to the University of Georgia
                in Athens, GA for her surgical residency. 
              </p>
              <p>
                Dr. Hixon’s passion for orthopedics and orthopedic surgery is drawn
                from being a lifelong athlete and outdoors enthusiast with her border
                collie. She loves helping owners and their pets return to their active
                lifestyles, which is what drew her and her fiancé, a physical therapist,
                to Colorado. In her free time, Dr. Hixon enjoys spending time playing
                sports and getting outdoors with her fiancé and two dogs, Cassie and Hiccup. 
              </p>
            </Col>
          </Row>
        </div>
        <div className="doctor-video">
          <Row className="border-none">
            <Col md={12}>
              <div className="embed-container">
                <iframe
                  src="https://player.vimeo.com/video/889241981"
                  frameborder="0"
                  webkitAllowFullScreen
                  mozallowfullscreen
                  allowFullScreen
                  title="doctor"
                ></iframe>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <BackToTop />
    </Layout>
  )
}

export default LeahHixon
